// src/services/orderService.js
import axios from 'axios';

import {Keys} from '/src/config.js';
const API_BASE_URL = Keys.API_BASE_URL;

export default {
  getOrders() {
    return axios.get(`/orders`);
  },
  getOrder(id) {
    return axios.get(`/orders/${id}`);
  },
  createOrder(order) {
    return axios.post(`/orders/create`, order);
  },
  updateOrder(id, order) {
    return axios.put(`/orders/${id}`, order);
  },
  deleteOrder(id) {
    return axios.delete(`/orders/${id}`);
  }
};
