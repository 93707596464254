<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ isEditing ? 'Edit Order' : 'Create Order' }}
        <v-spacer></v-spacer>
        <v-btn type="submit" @click="submitForm" color="primary">
          {{ isEditing ? 'Update' : 'Create' }}
          <v-icon right dark>mdi-content-save</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="text-subtitle-1">{{ order.meal.name }} </span><br>
            Descritpton
            <v-card-text>
              {{  order.meal.description  }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            Ordered By: {{ order.user.name.toUpperCase() }} <br>
            Student: {{ order.student.name.toUpperCase() }}
          </v-col>
          
          <v-col>
            School: {{ order.student.student_school.name }}
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            Quantity By: {{ order.quantity }} 
          </v-col>
          
          <v-col>
            Total Paid: {{ order.total_price }}
          </v-col>
        </v-row>

        <v-form @submit.prevent="submitForm">
          <div class="status-buttons">
            <span>Status:</span>
            <v-btn
              v-for="status in statuses"
              :key="status"
              :color="order.status === status ? 'primary' : 'secondary'"
              @click="order.status = status"
            >
              {{ status }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import orderService from '@/services/OrderService';

export default {
  data() {
    return {
      order: {
        customer_name: '',
        total_price: 0,
        quantity: 0,
        status: 'pending',
        user: {
          name: '',
        },
        student: {
          name: '',
        },
        meal: {
          name: '',
          description: '',
        },
      },
      statuses: ['pending', 'completed', 'cancelled'],
      isEditing: false,
      error: null
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      try {
        const response = await orderService.getOrder(this.$route.params.id);
        this.order = response.data;
      } catch (err) {
        this.error = 'Error fetching order details.';
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        if (this.isEditing) {
          await orderService.updateOrder(this.$route.params.id, this.order);
        } else {
          await orderService.createOrder(this.order);
        }
        this.$router.push({ name: 'orders' });
      } catch (err) {
        this.error = 'Error submitting form.';
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.status-buttons {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.status-buttons span {
  margin-right: 10px;
}

.status-buttons v-btn {
  margin-right: 10px;
}
</style>
